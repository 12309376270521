<template>
  <router-link :to="{ name: 'account', params: { userID: id }}" class="rating-element">
    <div class="position">
      <span> {{ '#' + position }} </span>
    </div>

    <a-avatar :size="24" :src="avatar">
      <a-icon v-if='!user.photo' slot="icon" type="user"/>
    </a-avatar>

    <span class="username"> {{ name }} </span>

    <div v-if='user.country.icon' class="country">
      <img :src="user.country.icon" alt='country icon'>
    </div>

    <div class="rating">
      <svg class="ico-star">
        <use xlink:href="@/assets/img/svg-sprite.svg#icoStar"></use>
      </svg>

      <span> {{ rating }} </span>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'User',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    id () {
      return this.user.id
    },
    position () {
      return this.user.position
    },
    rating () {
      return this.user.rating
    },
    avatar () {
      return this.user.photo
    },
    name () {
      return this.user.name
    }
  }
}
</script>
