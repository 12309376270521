<template>
  <view-container name="ratings">
    <view-header>
      <custom-header :router-back="$goBack">
        <template v-slot:default>
          <svg-icon size="size24x24" name="icoArrowLeft"/>
          Rating Table
        </template>
      </custom-header>

      <div class="ratings-header">
        <a-radio-group class="rating-type-filter scroll-hide" v-model="type">
          <a-radio-button v-for="type in types" :key="type.value" :value="type.value">
            {{ type.title }}
          </a-radio-button>
        </a-radio-group>

        <PinnedUser v-if='type===1 || type===2'/>
      </div>
    </view-header>

    <view-content>
      <div v-if='type===1 || type===2' class="rating-table">
        <div  class="rating-table-elements">
          <User v-for="user in users" :user="user" :key="user.id"/>
        </div>
      </div>
      <div v-else>Coming soon</div>

      <btn-up-rating/>
    </view-content>
  </view-container>
</template>

<script>
import { mapState } from 'vuex'
import User from '@/components/Rating/User'
import PinnedUser from '@/components/Rating/PinnedUser'
import BtnUpRating from '@/components/Rating/BtnUpRating'

export default {
  name: 'Ratings',
  components: {
    User,
    PinnedUser,
    BtnUpRating
  },
  props: ['userID'],
  data () {
    return {
      type: 1,
      types: [
        { value: 1, title: 'Absolute' },
        { value: 2, title: 'Swimming' },
        { value: 3, title: 'Workout' },
        { value: 4, title: 'Basketball' },
      ]
    }
  },
  watch: {
    type () {
      this.$store.dispatch('getRatingUser')
    }
  },
  mounted () {
    this.$store.dispatch('getRatingUser')
  },
  computed: {
    ...mapState({
      users: ({ rating }) => rating.ratings
    })
  }
}
</script>
