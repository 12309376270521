<template>
  <div class="rating-pinned-element">
    <div class="position">
      <span> {{ '#' + position }} </span>

      <svg-icon
        class="trend"
        size="size20x20"
        name="ico-arrow-right-up" />
    </div>

    <div class="user">
      <a-avatar :size="24" :src="avatar">
        <a-icon slot="icon" type="user"/>
      </a-avatar>

      <span class="username"> You </span>
    </div>

    <div class="rating">
      <svg class="ico-star">
        <use xlink:href="@/assets/img/svg-sprite.svg#icoStar"></use>
      </svg>

      <span> {{ rating }} </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PinnedUser',
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user
    }),
    rating () {
      return this.user?.rating || 0
    },
    position () {
      return this.user?.position || 0
    },
    avatar () {
      return this.user?.photo
    }
  }
}
</script>
