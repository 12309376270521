<template>
  <div
      data-vue-name="BtnUpRating"
      class="btn-up-rating"
  >
    <a
        :style='{ display: activeBlock }'
        class="back_to_top"
        title="up"
    >
      <svg-icon
          size="size24x24"
          name="up-arrow"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: "BtnUpRating",
  data: () => ({
    activeBlock: 'display',
    activeBlockDown: 'display'
  }),
  methods: {
    trackScroll () {
      const scrolled = window.pageYOffset
      const coords = document.documentElement.clientHeight

      if (scrolled > coords) {
        this.activeBlock = 'block'
      }
      if (scrolled < coords) {
        this.activeBlock = 'none'
      }
    },
    trackScrollDown () {
      const scrolled = window.pageYOffset
      const coords = document.documentElement.clientHeight

      if (scrolled > coords) {
        this.activeBlockDown = 'block'
      }
      if (scrolled < coords) {
        this.activeBlockDown = 'none'
      }
    },

    backToTop () {
      document.querySelector('#view-content').scrollTop = 0;
    },
  },
  mounted () {
    const goTopBtn = document.querySelector('.back_to_top')
    window.addEventListener('scroll', this.trackScroll)
    window.addEventListener('scroll', this.trackScrollDown)
    goTopBtn.addEventListener('click', this.backToTop)
  }
}
</script>

<style lang="stylus">
.btn-up-rating {
  position absolute
  right 16px
  bottom 53px
  display flex
  justify-content center
  align-items center
  width 56px
  height 56px !important
  background: #D21341;
  box-shadow: 0 0 12px rgba(25, 26, 30, 0.5);
  border-radius 23px
  z-index 1000

  svg {
    margin 0 0 0 5px
  }
}
</style>
